import payload_plugin_LajYjgAvnq from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.8_typescript@4.9.5_vue@3.5.13_typescript@4.9.5___rollup@4.28.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_BEnYyZyH1z from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.9_eslint@8.57.1_ioredis@5.4.1_magicast@0._ruucxm5k2o6hhsctrfbvgmy4x4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_kUczRLnTo2 from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.9_eslint@8.57.1_ioredis@5.4.1_magicast@0._ruucxm5k2o6hhsctrfbvgmy4x4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kuwR7BSCr2 from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.9_eslint@8.57.1_ioredis@5.4.1_magicast@0._ruucxm5k2o6hhsctrfbvgmy4x4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_yTguVeck87 from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.9_eslint@8.57.1_ioredis@5.4.1_magicast@0._ruucxm5k2o6hhsctrfbvgmy4x4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_4hKlJQRS7W from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.9_eslint@8.57.1_ioredis@5.4.1_magicast@0._ruucxm5k2o6hhsctrfbvgmy4x4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_B7JcKyydbp from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.9_eslint@8.57.1_ioredis@5.4.1_magicast@0._ruucxm5k2o6hhsctrfbvgmy4x4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_5zSxqKgP5Z from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.8_typescript@4.9.5_vue@3.5.13_typescript@4.9.5___rollup@4.28.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/.nuxt/components.plugin.mjs";
import prefetch_client_6csexA8uk8 from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.9_eslint@8.57.1_ioredis@5.4.1_magicast@0._ruucxm5k2o6hhsctrfbvgmy4x4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/.nuxt/unocss.mjs";
import sentry_client_fymoo67wuL from "/builds/asar-studio/ticket-me-biz/ticketme/libs/common/dist/runtime/plugins/sentry.client.js";
import plugin_AEz4B3WxtT from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.2.8_typescri_l23ihdaj3rqeuyt7fcdirphxau/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  payload_plugin_LajYjgAvnq,
  revive_payload_client_BEnYyZyH1z,
  unhead_kUczRLnTo2,
  router_kuwR7BSCr2,
  navigation_repaint_client_yTguVeck87,
  check_outdated_build_client_4hKlJQRS7W,
  chunk_reload_client_B7JcKyydbp,
  plugin_vue3_5zSxqKgP5Z,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6csexA8uk8,
  unocss_MzCDxu9LMj,
  sentry_client_fymoo67wuL,
  plugin_AEz4B3WxtT
]